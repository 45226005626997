import { createRoot } from 'react-dom/client';
import 'swiper/css/bundle';

import './style.scss';
import APBPosts from './Components/Frontend/APBPosts';

document.addEventListener('DOMContentLoaded', () => {
	const postsEls = document.querySelectorAll('.wp-block-ap-block-posts');
	postsEls.forEach(postsEl => {
		const nonce = JSON.parse(postsEl.dataset.nonce);
		const attributes = JSON.parse(postsEl.dataset.attributes);
		const { cId } = attributes;
		const { totalPosts } = JSON.parse(postsEl.dataset.extra);

		const firstPosts = window[`firstPagePosts-${cId}`] || [];

		createRoot(postsEl).render(<APBPosts firstPosts={firstPosts} nonce={nonce} totalPosts={totalPosts} attributes={attributes} />);

		window[`firstPagePosts-${cId}`] = undefined;
		const scriptTag = document.getElementById(`firstPagePostsScript-${cId}`);
		if (scriptTag) {
			scriptTag.remove();
		}

		postsEl?.removeAttribute('data-nonce');
		postsEl?.removeAttribute('data-attributes');
		postsEl?.removeAttribute('data-extra');
	});
});